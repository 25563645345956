<template>
<div class="card pointer">
      <div class="tooltip-box tooltip-box--price mb-2">
        <span v-if="option.discount > 0" class="badge-sale">{{ option.discount }}%</span>
        <Tooltip v-if="option.tooltip.text || option.tooltip.image" :tooltip="option.tooltip">
          <button class="btn-reset btn-tooltip">
            <i class="fas fa-info-circle"></i>
          </button>
        </Tooltip>
      </div>
      <div class="text-center pt-3">
        <img
        :src="$filters.image(option.image)"
        class="img-fluid"
        alt=""
      />
      </div>
      <div class="card-body">
        <div class="option__name mb-2">{{ option.name }}</div>
      <div class="counter-box mb-2">
        <button class="btn-reset counter-box__btn" @click="sub"><i class="fas fa-minus"></i></button>
        <input type="text" class="counter-box__input" disabled :value="option.value + ' ' + option.price_unit">
        <button class="btn-reset counter-box__btn"  @click="add"><i class="fas fa-plus"></i></button>
      </div>
      </div>
      <div v-if="option.discount > 0" class="text-center mb-2">
        <span class="option__price option__price--discount">{{ $filters.currency(price) }}</span>
        <span class="option__price option__price--old">{{ $filters.currency(oldPrice) }}</span>
      </div>
      <div v-else class="text-center mb-2">
        <span class="option__price option__price--discount">{{ $filters.currency(price) }}</span>
      </div>
      <div class="card-footer bg-primary text-white text-center" :class="{'not-selected': !option.selected}">
          <span v-if="option.selected">Ausgewählt</span>
          <span v-else>Auswählen</span>
      </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'SelectOption',
  props: {
    option: {
      required: true,
      type: Object
    }
  },
  setup (props) {
    const store = useStore()

    const priceAll = computed(() => {
      const currentStep = store.getters['configurator/currentStep']
      return currentStep.price_all || false
    })
    const totalPrice = computed(() => {
      const totalPrice = store.getters['configurator/totalPrice']
      return totalPrice(props.option)
    })
    const price = computed(() => {
      const option = props.option
      if (!option) {
        return null
      }
      if (priceAll.value) {
        return totalPrice.value.discounted || totalPrice.value.total
      }
      if (option.price) {
        if (!isNaN) {
          return option.price.discounted
        } else {
          if (option.discount > 0) {
            const discount = (Number.parseFloat(option.discount) / 100) * option.price.self
            let totalDiscount = 0
            totalDiscount = option.price.self - discount
            return totalDiscount
          } else {
            return option.price.self
          }
        }
      }
      return 0
    })
    const oldPrice = computed(() => {
      const option = props.option
      if (!option) {
        return null
      }
      if (priceAll.value) {
        return totalPrice.value.old_price
      }
      if (option.price) {
        return option.price.self
      }
      return 0
    })
    function onClickOption () {
      if (props.option.disabled) {
        return
      }
      if (props.option.type === 'check') {
        store.dispatch('configurator/toggleOption', props.option)
      } else {
        store.dispatch('configurator/selectOption', props.option)
      }
    }
    function add () {
      if (props.option.disabled) {
        return
      }
      const value = Math.min(Number.parseInt(props.option.value) + Number.parseInt(props.option.count_step), Number.parseInt(props.option.max))
      store.dispatch('configurator/setValue', { option: props.option, value: value })
    }
    function sub () {
      if (props.option.disabled || props.option.value === 0) {
        return
      }
      if (props.option.value === 0) {
        return 0
      }
      const value = Math.min(Number.parseInt(props.option.value) - Number.parseInt(props.option.count_step), Number.parseInt(props.option.min))
      store.dispatch('configurator/setValue', { option: props.option, value: value })
    }
    onMounted(() => {
      if (priceAll.value) {
        store.dispatch('configurator/calculatePriceAll', { option: props.option })
      }
    })
    return {
      price,
      oldPrice,
      onClickOption,
      add,
      sub
    }
  }
}
</script>
<style scoped>
.not-selected {
  opacity: .5;
}
.card {
  height: 100%;
}
</style>
